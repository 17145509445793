import { each, get, isEmpty, mergeWith, omitBy, startsWith, unset } from 'lodash';

function mergeCustomizer(a, b) {
    if (b instanceof Array) {
        return b;
    }
    return undefined;
}

function getBlueprintConfig(blueprintId, defaultConfiguration) {
    let blueprintConfig;
    if (blueprintId && defaultConfiguration.blueprints && defaultConfiguration.blueprints[blueprintId]) {
        blueprintConfig = mergeWith({}, defaultConfiguration.blueprints[blueprintId], mergeCustomizer);
    }
    return blueprintConfig;
}

export default class Configuration {
    constructor() {
        this.configuration = {};
        this.injectedConfig = {};
        this.defaultConfig = {};
        this.blueprintConfig = {};
    }

    getConfig(mwcIdPath, defaultConfig, injectedConfig) {
        this.registerComponent(mwcIdPath, defaultConfig, injectedConfig);
        const instanceConfig = this.getConfigFromId(mwcIdPath);
        return instanceConfig;
    }

    setAppConfig(appConfig) {
        mergeWith(this.configuration, appConfig, mergeCustomizer);
    }

    registerComponent(mwcIdPath, defaultConfig, injectedConfig = {}) {
        // Reset injected config every time there's a new register(except it was already been injected)
        // I.e., if component is reinstantiated
        const isInjectedValueEmpty = isEmpty(get(this.injectedConfig, mwcIdPath, {}));

        if (!!injectedConfig && isInjectedValueEmpty) {
            this.injectedConfig[mwcIdPath] = injectedConfig;
        }

        // If something has already set this defaultConfig, make sure we do not overwrite the value.
        this.defaultConfig[mwcIdPath] = mergeWith({}, defaultConfig, this.defaultConfig[mwcIdPath], mergeCustomizer);
    }

    getConfigFromId(id) {
        const mergedConfiguration = {};
        const mwcIdPathArray = id ? id.split('.') : [];
        let currentMwcIdPath = '';
        let accumulatedConfiguration;
        const parentGlobalConfig = mergeWith({}, this.configuration);
        let parentBlueprintConfig;

        each(mwcIdPathArray, (mwcId, depth) => {
            currentMwcIdPath = isEmpty(currentMwcIdPath) ? mwcId : `${currentMwcIdPath}.${mwcId}`;

            const defaultConfiguration = get(this.defaultConfig, currentMwcIdPath, {});
            const injectedConfiguration = get(this.injectedConfig, currentMwcIdPath, {});

            // Get the global config for this component
            let globalConfiguration = get(
                this.configuration,
                `components.${currentMwcIdPath.split('.').join('.components.')}`,
                {}
            );

            // Merge the config for this component with that from the parent so that setting values from application config cascade down
            delete parentGlobalConfig.components;
            parentGlobalConfig.settings = omitBy(parentGlobalConfig.settings, (value, key) => startsWith(key, '_'));
            globalConfiguration = mergeWith(parentGlobalConfig, globalConfiguration);

            const componentConfigFromParent = get(
                accumulatedConfiguration || defaultConfiguration,
                `components.${mwcId}`,
                {}
            );
            accumulatedConfiguration = mergeWith({}, defaultConfiguration, componentConfigFromParent, mergeCustomizer);

            // Get the blueprint config if one is applied
            const blueprintId =
                globalConfiguration.blueprint || injectedConfiguration.blueprint || accumulatedConfiguration.blueprint;
            const blueprintConfig = getBlueprintConfig(blueprintId, defaultConfiguration);
            if (blueprintConfig) {
                // Override the blueprint config with any component-specific config from the parent
                this.blueprintConfig[currentMwcIdPath] = mergeWith(
                    blueprintConfig,
                    componentConfigFromParent,
                    mergeCustomizer
                );
                if (!parentBlueprintConfig) {
                    parentBlueprintConfig = blueprintConfig;
                }
                // Don't inherit the blueprint value
                unset(globalConfiguration, 'blueprint');
                unset(injectedConfiguration, 'blueprint');
            }

            // Get config for this component from the blueprint applied to the parent
            let componentBlueprintConfig =
                parentBlueprintConfig && get(parentBlueprintConfig, `components.${mwcId}`, {});

            // Merge with config from the parent blueprint
            componentBlueprintConfig = mergeWith(parentBlueprintConfig, componentBlueprintConfig);

            // Is there a blueprint applied to this component?
            const componentBlueprint = getBlueprintConfig(componentBlueprintConfig.blueprint, defaultConfiguration);
            if (componentBlueprint) {
                componentBlueprintConfig = mergeWith(componentBlueprint, componentBlueprintConfig, mergeCustomizer);
                unset(componentBlueprintConfig, 'blueprint');
            }
            if (!this.blueprintConfig[currentMwcIdPath]) {
                this.blueprintConfig[currentMwcIdPath] = componentBlueprintConfig;
            }

            const mergedBlueprintConfig = get(this.blueprintConfig, currentMwcIdPath, {});
            parentBlueprintConfig = mergeWith({}, mergedBlueprintConfig, mergeCustomizer); // Save the component blueprint for the next pass
            parentBlueprintConfig.settings = omitBy(parentBlueprintConfig.settings, (value, key) =>
                startsWith(key, '_')
            );

            mergeWith(
                mergedConfiguration,
                accumulatedConfiguration,
                injectedConfiguration,
                mergedBlueprintConfig,
                globalConfiguration,
                mergeCustomizer
            );

            // Remove all private settings and child components, unless this is the last node.
            if (depth < mwcIdPathArray.length - 1) {
                mergedConfiguration.settings = omitBy(mergedConfiguration.settings, (value, key) =>
                    startsWith(key, '_')
                );
                unset(mergedConfiguration, 'components');
                unset(mergedConfiguration, 'blueprint');
                unset(mergedConfiguration, 'blueprints');
            }
        });

        return mergedConfiguration;
    }
}
