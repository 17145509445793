import i18next from 'i18next';

const defaultLng = 'en';

export default class Labeller {
    constructor(languageId, labels, namespace) {
        this.labels = labels || {};
        this.namespace = namespace;
        this.baseLanguageId = this.constructor.parseBaseLanguageId(languageId);
        this.initLanguage(languageId);
        this.initLabels(this.labels);
        this.translate = i18next.getFixedT(null, this.namespace);
    }

    exists(key) {
        return i18next.exists(key, { ns: this.namespace });
    }

    getLabel(key, interpolations) {
        return this.translate(key, interpolations);
    }

    updateLanguage(languageId) {
        languageId = languageId || defaultLng;
        if (languageId !== this.languageId) {
            this.languageId = languageId;

            const baseLanguageId = this.constructor.parseBaseLanguageId(languageId);
            if (baseLanguageId === this.baseLanguageId) {
                // We only need to change the language
                i18next.changeLanguage(languageId);
            } else {
                this.baseLanguageId = baseLanguageId;

                // We need to change the fallbackLng, so we reinitialize
                this.initLanguage(languageId);
                this.initLabels(this.labels);
            }
        }
    }

    initLabels(labels) {
        Object.keys(labels).forEach(languageId => {
            i18next.addResourceBundle(languageId, this.namespace, labels[languageId]);
        });
    }

    initLanguage(languageId) {
        this.languageId = languageId || defaultLng;

        if (!i18next.language || i18next.language !== this.languageId) {
            const fallbackLngs = this.createFallBackLngList(this.languageId);
            i18next.init({
                lng: this.languageId,
                fallbackLng: [this.languageId].concat(fallbackLngs),
                debug: true,
                keySeparator: false,
                nsSeparator: false,
                initImmediate: false,
            });
        }
    }

    createFallBackLngList(languageId) {
        const baseLanguage = this.constructor.parseBaseLanguageId(languageId);
        return baseLanguage ? [baseLanguage, defaultLng] : [defaultLng];
    }

    static parseBaseLanguageId(languageId) {
        return languageId ? languageId.split('-')[0] : defaultLng;
    }
}
